<template>
  <div ref="ModalBoxRef">
    <a-modal
      v-model:visible="visible"
      :onCancel="handleCancel"
      @ok="handleOk"
      :getContainer="() => ModalBoxRef"
      centered
    >
      <p class="title">{{ isEdit ? '编辑' : '新增' }}引导页</p>
      <a-divider />
      <template #footer>
        <a-button key="back" @click="handleCancel">取消</a-button>
        <a-button key="submit" type="primary" :loading="loading" @click="handleOk">确定</a-button>
      </template>
      <a-form :label-col="labelCol" :wrapper-col="wrapperCol">
        <a-form-item label="图片上传">
          <a-upload-dragger
            class="cover-file"
            :file-list="fileList"
            :remove="removeFile"
            :customRequest="selfUpload"
            @change="handleChange"
          >
            <p class="ant-upload-drag-icon" v-show="!picture">
              <plus-outlined></plus-outlined>
            </p>
            <img :src="picture" v-show="picture" class="cover-img" />
          </a-upload-dragger>
        </a-form-item>
        <a-form-item label="引导页名称">
          <a-input v-model:value.trim="name" placeholder="请输入引导页名称" allowClear></a-input>
        </a-form-item>

        <a-form-item label="引导页顺序">
          <a-input-number v-model:value="sortId" :min="1" allowClear></a-input-number>
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>
<script>
import { defineComponent, ref, reactive, toRefs, onMounted, nextTick } from 'vue';
import { PlusOutlined } from '@ant-design/icons-vue';
import api from '@/services';
import { message } from 'ant-design-vue';

export default defineComponent({
  components: {
    PlusOutlined
  },
  setup(props, context) {
    const loading = ref(false);
    const visible = ref(false);

    const isEdit = ref(false);
    const ModalBoxRef = ref();

    const fileList = ref([]); // 上传图片列表

    const labelCol = reactive({ span: 4, offset: 3 });
    const wrapperCol = reactive({ span: 14 });
    const formState = reactive({
      id: '',
      name: '',
      picture: '',
      sortId: 0
    });

    const showModal = (flag, rowData) => {
      visible.value = true;
      nextTick(() => {
        isEdit.value = flag;
        if (flag) {
          Object.keys(formState).map(key => {
            formState[key] = rowData[key];
          });
          formState.picture = 'data:image/jpg;base64,' + rowData.picture;
        }
      });
    };

    const handleOk = async () => {
      loading.value = true;
      Object.keys(formState).map(key => {
        if(!formState[key]) {
          loading.value = false
        }
      });
      if(!loading.value) {
        message.error('请输入完整')
        return false 
      }
      const functionName = isEdit.value ? 'updateGuidePage' : 'addGuidePage';

      let params = {
        ...formState
      };

      const { success } = await api[functionName](params);
      if (success) {
        message.success(`${isEdit.value ? '编辑' : '新增'}成功！`);
        context.emit('addOrUpdateSuccess');
      }
      loading.value = false;
      visible.value = false;
    };

    const handleCancel = () => {
      visible.value = false;
      clearForm();
    };

    const clearForm = () => {
      fileList.value = '';
      Object.keys(formState).map(key => {
        formState[key] = '';
      });
    };

    // base64转换
    const selfUpload = ({ file }) => {
      new Promise(resolve => {
        const fileReader = new FileReader();
        fileReader.readAsDataURL(file);
        fileReader.onload = () => {
          resolve(fileReader.result);
          formState.picture = fileReader.result;
        };
      });
    };
    // 修改上传状态 和 限制上传数量
    const handleChange = info => {
      let resFileList = [...info.fileList];
      resFileList = resFileList.slice(-1);
      fileList.value = resFileList;
      if (fileList.value.length) fileList.value[0].status = 'done';
    };

    // 删除
    const removeFile = () => {
      formState.picture = '';
    };

    return {
      fileList,
      removeFile,
      handleChange,
      selfUpload,
      isEdit,
      labelCol,
      wrapperCol,
      ...toRefs(formState),
      loading,
      visible,
      showModal,
      handleOk,
      handleCancel,
      ModalBoxRef,
      clearForm
    };
  }
});
</script>

<style lang="less" scoped>
:deep(.ant-modal-body) {
  .title {
    font-size: 18px;
    font-weight: bold;
    text-align: center;
  }
  .ant-form {
    .ant-form-item {
      .ant-upload-drag-icon {
        margin-bottom: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        :deep(.anticon-plus) {
          color: #d4d4d4;
          font-size: 24px;
        }
      }
      .cover-img {
        height: 148px;
        border-radius: 10px;
      }
    }
  }
}
</style>
